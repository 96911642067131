<template>
    <div class="cloud-storage__item">
        <div class="cloud-storage__meta">
            <span class="cloud-storage__memory">Занято {{data.used}} мб из {{data.total}} гб</span>
        </div>
        <div class="cloud-storage__progress">
            <div
                class="cloud-storage__bar"
                :style="{
                    width: `${data.used_percent}%`,
                    display: data.used_percent > 1 ? 'flex' : 'none',
                    borderRadius: data.used_percent === 100 ? '50px' : '50px 0 0 50px'
                }"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "CloudStorageItem",
         props: {
            data: {
                type: Object,
                default: {
                    used: 0,
                    used_percent: 0,
                    total: 0,
                }
            }
        }
    }
</script>

<style lang="sass" scoped>
    @import '#sass/v-style'

    .cloud-storage
        &__item
            display: flex
            flex-direction: column
            width: 100%
            &:nth-child(n+2)
                margin-top: 30px
        &__meta
            display: flex
            justify-content: space-between
            color: #000
        &__progress
            width: 100%
            position: relative
            display: flex
            align-items: center
            height: 40px
            border: 1px solid $gold
            border-radius: 50px
            font-size: 1rem
            padding: 0 20px
            margin-top: 7px
            overflow: hidden
        &__bar
            position: absolute
            z-index: 4
            border-radius: 50px
            left: 0
            width: 50%
            height: 100%
            content: ''
            padding: 3px
            border: 2px solid #fff
            background: $gold
</style>
